@font-face {
    font-family: 'Atyp Display';
    src: url('./AtypDisplay-Thin.woff2') format('woff2'),
            url('./AtypDisplay-Thin.woff2') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Atyp Display';
    src: url('./AtypDisplay-Light.woff2') format('woff2'),
            url('./AtypDisplay-Light.woff2') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Atyp Display';
    src: url('./AtypDisplay-Regular.woff2') format('woff2'),
            url('./AtypDisplay-Regular.woff2') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Atyp Display';
    src: url('./AtypDisplay-Medium.woff2') format('woff2'),
            url('./AtypDisplay-Medium.woff2') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Atyp Display';
    src: url('./AtypDisplay-Semibold.woff2') format('woff2'),
            url('./AtypDisplay-Semibold.woff2') format('woff');
    font-weight: 600;
    font-style: normal;
}
  
@font-face {
    font-family: 'Atyp Display';
    src: url('./AtypDisplay-Bold.woff2') format('woff2'),
            url('./AtypDisplay-Bold.woff2') format('woff');
    font-weight: 700;
    font-style: normal;
}
  