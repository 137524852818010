/* styles.css */
.slick-track {
    display: flex;
    align-items: center;
}

.styled-slider .slick-slide div {
    height: 100%;
}

.styled-slider .slide {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: column;
}
  